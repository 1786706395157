import React from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parse from "date-fns/parse";

/**
 *
 * @param children string
 * @returns {*}
 */
export default ({ children }) => {
  return (
    <>
      {typeof window !== 'undefined'
        ? formatDistanceToNow(parse(children, "MMMM dd, yyyy", new Date()), {
            addSuffix: true
          })
        : children}
    </>
  );
};
