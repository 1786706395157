import React from "react";

import Layout from "../components/layout";
import Posts from '../components/posts';

const BlogPage = () => {
  return (
    <Layout>
      <Posts />
    </Layout>
  );
};

export default BlogPage;
