import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import RelativeDate from "./relative-date";

const query = graphql`
  query HomePageQuery {
    sidebar: allMarkdownRemark(
      skip: 5
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fields: { type: { eq: "post" } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            title
            excerpt
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    featured: allMarkdownRemark(
      limit: 5
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fields: { type: { eq: "post" } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            title
            excerpt
          }
        }
      }
    }
  }
`;

const Posts = () => (
  <StaticQuery
    query={query}
    render={({
      sidebar: { edges: sidebar },
      featured: { edges: featured }
    }) => (
      <section id="main">
        <div className="container">
          <header>
            <h2>
              <Link to="/blog">
                Writing by <strong>Malissa</strong>
              </Link>
            </h2>
          </header>
          <div className="row">
            <div id="content" className="col-8 col-12-medium">
              {featured.map(({ node: { fields, frontmatter } }) => {
                return (
                  <article className="box post" key={fields.path}>
                    <header>
                      <h3>
                        <Link to={fields.path}>{frontmatter.title}</Link>
                      </h3>
                    </header>
                    {frontmatter.excerpt}
                    <ul className="actions">
                      <li>
                        <Link
                          to={fields.path}
                          className="button button-icon icon icon-file"
                        >
                          Continue Reading
                        </Link>
                      </li>
                    </ul>
                  </article>
                );
              })}
            </div>
            <div id="sidebar" className="col-4 col-12-medium">
              <section>
                <ul className="divided">
                  {sidebar.map(({ node: { fields, frontmatter } }) => {
                    return (
                      <li key={fields.path}>
                        <article className="box excerpt">
                          <header>
                            <span className="date">
                              <RelativeDate>{frontmatter.date}</RelativeDate>
                            </span>
                            <h3>
                              <Link to={fields.path}>{frontmatter.title}</Link>
                            </h3>
                          </header>
                          {frontmatter.excerpt}
                        </article>
                      </li>
                    );
                  })}
                </ul>
              </section>
            </div>
          </div>
        </div>
      </section>
    )}
  />
);

export default Posts;
